import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f187beec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-h6"
}
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar, { app: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_app_bar_nav_icon, {
                variant: "text",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.drawer = !$setup.drawer), ["stop"]))
              }),
              ($setup.route.meta?.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.route.meta.title), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_menu, null, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ icon: "" }, props), {
                    default: _withCtx(() => [
                      ($setup.userStore.userData?.avatar)
                        ? (_openBlock(), _createBlock(_component_v_avatar, {
                            key: 0,
                            size: "48"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_img, {
                                src: $setup.userStore.userData?.avatar?.url,
                                alt: "User Avatar"
                              }, null, 8, ["src"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        class: "cursor-pointer",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.userStore.clear()))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_v_icon, { size: 20 }, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-logout")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Logout")
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_navigation_drawer, {
            modelValue: $setup.drawer,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.drawer) = $event)),
            temporary: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuItems, (item, index) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: index,
                      to: { name: item.routerName },
                      link: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.icon), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_Transition, {
                    name: "fade",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}