import { ISelectOption } from "@/types/entities/ISelectOption";

const languages: ISelectOption[] = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Italian", value: "it" },
  { label: "Portuguese", value: "pt" },
  { label: "Spanish", value: "es" },
  { label: "Dutch", value: "nl" },
  { label: "Japanese", value: "ja" },
];

export default languages;
